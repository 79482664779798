import {
  BodyText,
  Custom_Block_Map_Font_H1,
  GridWithGaps,
  propertiesOverviewOtherLocaleUrls,
  SimpleMuiButton,
  useAnimateIconTranslationRight,
} from '@dreamstack/feature-components'
import { useLocale, useTranslation } from '@dreamstack/i18n'
import { motion } from 'framer-motion'
import Image from 'next/legacy/image'
import Link from 'next/link'
import type { FunctionComponent } from 'react'
import tw from 'twin.macro'

const RelativeContainer = tw.div`relative col-span-full`
const LeftContainer = tw.div`float-left absolute w-full top-1/4 md:(w-3/4 ) ml:(top-1/3)`
const RightContainer = tw.div`float-right relative ml-auto w-full md:(w-3/4) z-index[-1]`

export const AllPropertyGermanyMap: FunctionComponent<
  React.PropsWithChildren<{}>
> = () => {
  const t = useTranslation()
  const { onHoverStart } = useAnimateIconTranslationRight()
  const locale = useLocale()

  return (
    <GridWithGaps>
      <RelativeContainer>
        <LeftContainer>
          <BodyText>{t('accentro:germanyMapLanding.Heading1')}</BodyText>
          <Custom_Block_Map_Font_H1 tw="mt-px-16 mb-px-40 ml:(mt-0)">
            {t('accentro:germanyMapLanding.Heading2')}
          </Custom_Block_Map_Font_H1>

          <Link
            href={propertiesOverviewOtherLocaleUrls[locale].url}
            passHref
            legacyBehavior
          >
            <motion.a onHoverStart={onHoverStart}>
              <SimpleMuiButton color="primary" variant="contained">
                {t('accentro:germanyMapLanding.Cta')}
              </SimpleMuiButton>
            </motion.a>
          </Link>
        </LeftContainer>
        <RightContainer>
          <div tw="relative height[400px] md:height[600px] ml:height[800px]">
            <Image
              src={`/static/images/germany-map.png`}
              alt="Map of Germany"
              layout="fill"
              tw="object-contain"
            />
          </div>
        </RightContainer>
      </RelativeContainer>
    </GridWithGaps>
  )
}
